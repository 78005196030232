/**
 * Truncate filename helper function.
 * Receives filename as string, and number of symbols. As results we get truncated name with '...'
 *
 * @param {string} name
 * @param {number} max
 * @returns {string}
 * @public
 */
export default (name, max = 10) => {
  const ext = name
    .substring(name.lastIndexOf('.') + 1, name.length)
    .toLowerCase();
  let filename = name.replace(`.${ext}`, '');

  if (filename.length <= max) {
    return name;
  }

  filename = `${filename.slice(0, max)}${name.length > max ? '...' : ''}`;
  return `${filename}.${ext}`;
};
