import URI from '@core/utils/url';
import paymentPendingVar from '@core/graphql/vars/paymentPendingVar';
import ROUTES from '@core/application/constants/routes';
import getHistory from '@core/application/utils/getHistory';
import {localStorage} from '@core/utils/storage';
import {DISABLE_USER_PAYMENT_PROCESSED_INTERACTION} from '@core/payment/common/constants/localStorageKeys';
import {ENABLE_REACT_PAY} from '@core/application/constants/bootstrapParams';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import isPayUrl from '@core/utils/url/isPayUrl';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';

import PaymentPageSuccessOrders from '../../payProcess/utils/PaymentPageSuccessOrders';
import USER_PAYMENT_PROCESSED_SUBSCRIPTION from '../graphql/subscriptions/userPaymentProcessed.gql';
import PAYMENT_STATUSES from '../constants/paymentStatuses';

let subscription = null;

/**
 * @desc Site pending interaction listener,
 *  need for listen answer from payment processor on site if user broke payment session
 */
const startUserPaymentProcessed = (client, updateCachesAfterPayment) => {
  if (subscription || getBootstrapParam(ENABLE_REACT_PAY)) {
    return;
  }

  subscription = client
    .subscribe({query: USER_PAYMENT_PROCESSED_SUBSCRIPTION})
    .subscribe(({data}) => {
      if (
        !data?.paymentProcessed ||
        localStorage.getItem(DISABLE_USER_PAYMENT_PROCESSED_INTERACTION)
      ) {
        return;
      }

      const {paymentStatus, orderId, redirectUrl, via} = data.paymentProcessed;

      if (paymentPendingVar() || paymentStatus !== PAYMENT_STATUSES.SUCCESS) {
        return;
      }

      if (window.location.pathname.startsWith(ROUTES.PAY) && redirectUrl) {
        const successPageUri = URI(redirectUrl)
          .setSearch({
            via,
            orderId,
          })
          .toString();

        if (isPaySuccessUrl(redirectUrl) || isPayUrl(redirectUrl)) {
          PaymentPageSuccessOrders.add(orderId, via);
        }

        getHistory().push(successPageUri);
      }

      updateCachesAfterPayment();
    });
};

export default startUserPaymentProcessed;
